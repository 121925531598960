import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    sessionTimeoutPollFrequency: { type: Number, default: 60000 },
    url: { type: String }
  }

  connect() {
    this.intervalId = setInterval(this.pollForSessionTimeout, this.sessionTimeoutPollFrequencyValue)
  }

  disconnect() {
    this.removeInterval()
  }

  removeInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  pollForSessionTimeout = () => {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        if (data.user_inactive) {
          window.location.reload()
        }
      })
      .catch(error => {
        // prevent error being sent to Sentry
        console.error(error)
      });
  }
}
