const Mustache = require("mustache")

AuthInbox.Shared.confirmation = function($el) {
  var confirmationTemplate =
    '<div class="modal">' +
      '<div class="modal__header">' +
        '<div class="modal__title"><span>{{ title }}</span></div>' +
      '</div>' +
      '<div class="modal__instructions"><p>{{ content }}</p></div>' +
      '<div class="modal__footer">' +
        '<a class="btn btn--blue mr-5 {{ button_class }}" href="{{ href }}" data-method="{{ method }}" data-remote="{{ remote }}">{{ button_text }}</a>' +
        '<a class="btn btn--gray cancel" href="#">Cancel</a>' +
      '</div>' +
    '</div>',
    data = $el.data("confirmation");

  $el.on("click", function(event) {
    AuthInbox.Utility.colorbox($el, {
      open: true,
      onComplete: setUp,
      closeButton: false,
      html: renderContent()
    });

    return false;
  });

  function setUp() {
    $("#colorbox").on("click", ".cancel", function() {
      $.colorbox.close();
    });

    $("#colorbox a:first").focus();
  }

  function renderContent() {
    return Mustache.render(confirmationTemplate, {
      title: data.title,
      content: data.content,
      href: $el.attr("href"),
      method: data.method,
      button_text: data.button_text,
      button_class: data.button_class || "btn--primary",
      remote: dataRemoteValue()
    });
  }

  function dataRemoteValue() {
    if ($.isUndefined(data.remote)) {
      return false;
    } else {
      return data.remote;
    }
  }
};
