AuthInbox.Shared.select2Basic = function($el) {
  var resultsClass     = $el.data("results-class"),
      placeholder      = $el.data("placeholder") || "Select",
      dropdownParent   = $el.data("dropdown-parent-class"),
      $dropdownParent  = $("." + $el.data("dropdown-parent-class")),
      matcherName      = $el.data("matcher"),
      allowClear       = $el.data("allow-clear") || false,
      triggerTarget    = $el.data("target"),
      disabledOptionId = $el.data("disabled-option-id"),
      daisySendMark    = $el.data("daisy-send-mark"),
      centerDropdown   = $el.data("center-dropdown"),

      matchers = {
        "matchStart": function matcher(term, text) {
          if (matcherName === "matchStart") {
            if (text.toUpperCase().indexOf(term.toUpperCase()) === 0) {
              return true;
            }

            return false;
          }
        }
      };

  if (!$el.data("select2Id")) {
    $el.select2({
      minimumResultsForSearch: 1,
      maximumSelectionSize: $el.data("item-limit"),
      dropdownCssClass: resultsClass,
      dropdownParent: $dropdownParent.length ? $dropdownParent : undefined,
      matcher: matchers[matcherName],
      allowClear: allowClear,
      placeholder: placeholder,
      width: 'resolved'
    });

    if (triggerTarget) {
      $(triggerTarget).trigger("select2container-loaded");
    }

    if (disabledOptionId) {
      $el.find('option[value="' + disabledOptionId + '"]').prop("disabled", true);
    }
  }

  var $arrow = $el.siblings(".select2-container").find(".select2-selection__arrow");

  $el.on("change", function() {
    if ($el.data("resize-on-change")) {
      $.colorbox.resize();
    }
  });

  $el.on("select2:select", function() {
    if (allowClear) {
      $arrow.hide();
    }

    let event = new Event("change", { bubbles: true });
    this.dispatchEvent(event);
  });

  $el.on("select2:unselect", function() {
    $arrow.show();
  });

  $el.on("select2:open", function() {
    if (centerDropdown) {
      var $dropdownContainer = $(".select2-dropdown"),
          $input             = $(".select2-selection__rendered"),
          calculatedPadding  = ($dropdownContainer.width() - $input.width()) / 2;

      $input.css("padding-left", calculatedPadding);
    }

    let searchFields = document.querySelectorAll(".select2-container--open .select2-search__field");
    searchFields[searchFields.length - 1].focus();
  });

  if (daisySendMark) {
    $el.parent().find(".select2").addClass("daisy-send-mark");
  }
};
