require("../utility/remote_form")

AuthInbox.Shared.showModalForm = function($el) {
  var href = $el.attr("href") || $el.attr("url");

  $el.attr("href", "");
  $el.attr("url", href);

  $el.on("click", function(event) {
    AuthInbox.Utility.remoteForm($el, {
      href: href,
      closeButton: false,
    });
    return false;
  });
};
