import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    mask: { type: String, default: "(999) 999-9999" },
    options: Object
  }

  connect() {
    Inputmask(this.maskValue, this.maskOptions).mask(this.element)
  }

  disconnect() {
    this.element.inputmask.remove()
  }

  get maskOptions() {
    return {
      removeMaskOnSubmit: true,
      ...this.optionsValue
    }
  }
}
