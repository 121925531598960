AuthInbox.Utility.positionModal = function() {
  var maxPotentialBodyHeight,
      verticalSpaceAroundModal = 100,
      $modal                   = $(".modal"),
      currentBodyHeight        = $(".modal__body").outerHeight();

  maxPotentialBodyHeight = $(window).height() - modalBlocksHeightSum() - verticalSpaceAroundModal;

  if ($modal.length) {
    if (currentBodyHeight > maxPotentialBodyHeight)  {
      $(".modal__body").height(maxPotentialBodyHeight);
      addScrollableClasses();
      $.colorbox.resize();
    } else if (modalOutOfWindow()) {
      $.colorbox.resize();
    } else {
      $(".modal__footer").removeClass("modal__footer--shadow-box");
    }
  }

  function modalBlocksHeightSum() {
    return $(".modal__header").outerHeight() +
      ($(".modal__instructions").outerHeight() || 0) +
      ($(".modal__warning").outerHeight() || 0) +
      $(".modal__footer").outerHeight();
  }

  function modalOutOfWindow() {
    return ($modal.offset().top - $(window).scrollTop() + $modal.outerHeight() >= $(window).height());
  }

  function addScrollableClasses() {
    $(".modal__footer").addClass("modal__footer--shadow-box");
    $(".modal__body").addClass("modal__body--scrollable");
  }
};
