import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "radio" ]
  static values  = { additionalContainerClass: String, submitButtonClass: String }

  connect() {
    this.togglePages()
  }

  toggle() {
    this.togglePages()
    this.enableSubmitButton()
  }

  showAdditionalContainer() {
    if (this.additionalContainerClassValue) {
      let container = document.getElementsByClassName(this.additionalContainerClassValue)[0]

      container.classList.remove("hidden")
    }
  }

  enableSubmitButton() {
    if (this.submitButtonClassValue) {
      let button = document.getElementsByClassName(this.submitButtonClassValue)[0]

      button.disabled = false
    }
  }

  changeDisabledProperty(inputs, property) {
    for (let input of inputs) {
      input.disabled = property
    }
  }

  togglePages() {
    this.radioTargets.forEach(radio => {
      let page   = document.querySelector(`[data-page=${radio.value}]`),
          inputs = page.getElementsByTagName("input")

      if (radio.checked) {
        page.classList.remove("hidden")
        this.showAdditionalContainer()
        this.changeDisabledProperty(inputs, false)
      } else {
        page.classList.add("hidden")
        this.changeDisabledProperty(inputs, true)
      }
    })

    $.colorbox.resize();
  }
}
