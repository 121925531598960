AuthInbox.Shared.dataTableDownloader = function($el) {
  var datatablePath      = $el.data("table-path"),
      params             = [],
      $downloadAsCsvLink = $(".js-download-csv", $el),
      $downloadPolling   = $(".js-download-polling", $el);

  $downloadAsCsvLink.on("click", createExport)

  function createExport(event) {
    pushParams();

    $.ajax({
      dataType: "json",
      url: datatablePath,
      data: params,
      success: createExportSuccess
    });

    event.preventDefault();
    displayPolling();
  }

  function pushParams() {
    $.each($downloadAsCsvLink.data(), function(key, value) {
      params.push({
        name: key,
        value: value
      })
    });
  }

  function createExportSuccess(response) {
    $downloadAsCsvLink.data("export-id", response.export_id);
    pollForLink();
  }

  function pollForLink() {
    var exportId = $downloadAsCsvLink.data("export-id");

    $.ajax(("/datatable_exports/" + exportId), {
      type: "GET",
      dataType: "json",
      success: pollSuccess
    });
  }

  function pollSuccess(response, status) {
    if (response.status === "completed") {
      window.location.href = response.document;
      displayDownloadLink();
    } else if (response.status === "failed") {
      $downloadPolling.html("An error has occurred.")
    } else {
      setTimeout(function() {
        pollForLink();
      }, 3000);
    }
  }

  function displayPolling() {
    $downloadAsCsvLink.hide();
    $downloadPolling.removeClass("hidden");
  }

  function displayDownloadLink() {
    $downloadPolling.addClass("hidden");
    $downloadAsCsvLink.show();
  }
};
