import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "checkbox", "container", "input", "button"]
  static values = {
    clearInput: Boolean
  }

  initialize() {
    $(this.selectTarget).on("select2:select", function() {
      let event = new Event("change", { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  enableSubmitButton() {
    this.buttonTarget.disabled = this.checkboxChecked && !this.selectTarget.value
  }

  showSelectContainer() {
    this.containerTarget.classList.toggle("hidden", !this.checkboxChecked)
    this.inputTarget.disabled = this.checkboxChecked
    this.selectTarget.disabled = !this.checkboxChecked
    if (this.clearInputValue) {
      this.inputTarget.value = ""
    }
    this.enableSubmitButton()
  }

  get checkboxChecked() {
    return this.checkboxTarget.checked
  }
}
