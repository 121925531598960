AuthInbox.Custom.embeddedArticlesControl = function($el) {
  var kbHosts = ["http://kb.localhost:3001", "https://staging-kb.daisybill.com", "https://kb.daisybill.com"];

  var documentCommands = {
    articleLoad: function (params) {
      $(".js-embedded-articles").height(params.articleHeight + 100);
    },
    updateUrl: function(params) {
      history.pushState(null, null, window.location.origin + "/help_articles/" + params.articleId);
    }
  }

  window.addEventListener("message", receiveCommand);

  function receiveCommand(event) {
    if (kbHosts.includes(event.origin)) {
      var command = event.data.command;

      if (command !== undefined) {
        documentCommands[command.name](command.data);
      }
    }
  }
};
