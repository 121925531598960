AuthInbox.Custom.headerSearch = function(form) {
  var focusClass          = "header-search--focus",
      $searchIcon         = $(".js-search-icon", form).find("i"),
      $headerSearch       = $(".js-header-search", form),
      $input              = $("#q", form),
      regex               = new RegExp("^\\d+$|^\\d+-\\d+$", "i"),
      $disclosure         = $(".js-caret", form),
      $caret              = $("i", $disclosure),
      $pane               = $(".js-advanced-search"),
      $claimNumberInput   = $("#q_claim_number"),
      advancedSearchClass = "nav-search--advanced";

  $disclosure.on("click", function() {
    toggleAdvancedSearch($pane.hasClass("hidden"));
    $input.val("");
    $claimNumberInput.focus();
  });

  $input.on("click", function() {
    toggleAdvancedSearch(false);
  });

  $input.on("focus", function() {
    form.addClass(focusClass);
  });

  $input.on("blur", function() {
    form.removeClass(focusClass);
  });

  form.on("submit", function() {
    form.prop("action", formAction());
  });

  $(window).click(function(e) {
    if ($pane.is(":visible") && e.target !== $disclosure.get(0) && e.target !== $caret.get(0)) {
      toggleAdvancedSearch(false);
    }
  });

  $pane.on("click", function() {
    event.stopPropagation();
  });

  $input.keyup(toggleAutocompleter);

  function isRfaSearch() {
    return regex.test($input.val());
  }

  function formAction() {
    return (isRfaSearch() ? "/rfa_search" : "/patients_search");
  }

  function toggleAutocompleter() {
    $input.autocomplete(isRfaSearch() ? "disable" : "enable");
    toggleAdvancedSearch(false);
  }

  function initializeAutocompleter() {
    $input.autocomplete({
      minLength: 2,
      position: { my : "right top+16", at: "right bottom", of: form },
      search: function(event, ui) {
        toggleIconClasses();
      },
      response: function(event, ui) {
        toggleIconClasses();
      },
      source: function(request, response) {
        $.ajax({
          url: "/patients_search",
          dataType: "json",
          data: { q: { patient_name: request.term } },
          success: function(data) {
            var results = $.map(data.results, function(result) {
              return {
                value: result.name,
                dob: result.dob,
                label: label(result)
              };
            });

            var uniqueResults = _.uniqWith(results, _.isEqual);

            response(uniqueResults);
          }
        });
      },
      select: function(event, ui) {
        var path = "/patients_search?q=" + ui.item.value;
        $input.val(ui.item.value).change();
        location.href = path;
        return false;
      }
    }).data("ui-autocomplete")._renderItem = function(ul, item) {
      $(ul).addClass("patient-search-autocomplete");
      return $("<div>" +
          "<span class='w-full'>" +
          item.value +
          "</span>" +
          item.label +
          "</div>"
        )
        .appendTo(ul);
    };
    if (form.hasClass("js-header-search")) {
      $input.data("ui-autocomplete")._resizeMenu = function() {
        var ul = this.menu.element;
        ul.outerWidth($(".js-header-search").width());
      };
    }
  }

  function label(result) {
    return " \
            <span class='pr-3'>DOB</span> \
            <span class='pr-0'>" + result.dob + "</span>";
  }

  function toggleIconClasses() {
    $searchIcon.toggleClass("fa-search fa-refresh fa-spin icon-pink");
  }

  initializeAutocompleter();

  function toggleAdvancedSearch(isAdvanced) {
    if (isAdvanced) {
      $pane.removeClass("hidden");
      $caret.addClass("fa-angle-up").removeClass("fa-angle-down");
      $headerSearch.addClass(advancedSearchClass);
    } else {
      $pane.addClass("hidden");
      $caret.removeClass("fa-angle-up").addClass("fa-angle-down");
      $headerSearch.removeClass(advancedSearchClass);
    }
  }
};
