AuthInbox.Custom.collapseTable = function($el) {
  var $toggleLink = $el.find(".js-collapse-toggle-link"),
      $container  = $el.find(".js-collapse-container");

  $toggleLink.on("click", function() {
    $container.toggle();
    $toggleLink.find(".js-collapse-toggle-icon").toggleClass("fa-angle-up fa-angle-down");
    $toggleLink.toggleClass("bg-blue-500 bg-gray-300");
    $el.find(".js-main-collapse-container").toggleClass("rounded-3xl rounded-t-3xl shadow-blue-lg");
    $el.toggleClass("pb-6");
  });
};
