AuthInbox.Utility.dropdown = function($el) {
  var $button = $(".js-dropdown-button", $el);

  $(window).click(function(e) {
    if (e.target !== $button.get(0) && $el.hasClass("open")) {
      $el.removeClass("open");
    }
  });

  $button.on("click", toggleDrop);

  $(".js-dropdown-container", $el).on("click", function() {
    event.stopPropagation();
  });

  function toggleDrop(_event) {
    $el.toggleClass("open");
  }
}
