export const initSentry = (Sentry) => {
  Sentry.init({
    dsn: window.sentryConfig.dsn,
    environment: window.sentryConfig.environment,
    enabled: true,
    release: document.querySelector('meta[name="release-sha"]')?.getAttribute("content"),
    integrations: [
      Sentry.replayIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["daisybill/auth-inbox"],
        behavior: "drop-error-if-contains-third-party-frames",
      })
    ],
  });

  if (window.sentryConfig.userId) {
    Sentry.setUser({
      id: window.sentryConfig.userId,
      email: window.sentryConfig.userEmail,
    });
  }
}
