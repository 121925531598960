AuthInbox.Shared.datePicker = function($container) {
  const moment     = require("moment");
  const dateFormat = "MM/DD/YYYY";

  var $btn             = $("span", $container),
      parentEl         = $container.data("parent-el"),
      $input           = $("input", $container),
      $startDateTarget = $container.closest(".js-date-picker").find(".js-start-date");

  $container.daterangepicker(
    {
      singleDatePicker: true,
      autoApply: true,
      opens: $container.data("opens"),
      drops: $container.data("drops"),
      cancelClass: "btn--outline",
      startDate: startDate(),
      alwaysShowCalendars: true,
      parentEl: $("." + parentEl)
    }, function(start) {
      $input
        .val(start.format(dateFormat))
        .trigger("change");
  });

  function startDate() {
    if (moment($startDateTarget.val(), dateFormat).isValid()) {
      return moment($startDateTarget.val(), dateFormat).format("L");
    } else {
      return moment().format("L");
    }
  }

  $container.on("apply.daterangepicker", function(ev, picker) {
    $input.val(picker.startDate.format(dateFormat)).trigger("change");

    let event = new Event("change", { bubbles: true });
    $input[0].dispatchEvent(event);
  });

  $btn.on("click", function() { $container.trigger("show.daterangepicker"); });
};
