AuthInbox.Custom.rfaFilter = function($el) {
  var $button            = $(".js-action-link"),
      $content           = $(".js-dropdown-filter"),
      $rfaContainer      = $(".rfa-filter__container[data-container=" + $el.data("filter-container") + "]"),
      $tabContainer      = $(".js-tasks-table");

  function implantFilter() {
    $(".js-action-content").append($(".js-filter-action"));
    $tabContainer.find(".dt-simple__table:visible").before($(".js-filter-container"));
  }

  function toggleDrop(_event) {
    $content.toggle();
  }

  function filterTable() {
    $("select[data-type=filter_input]", $tabContainer).trigger("change");
    $tabContainer.find("table").show();
    $tabContainer.find(".dataTables_wrapper").trigger("show");
  }

  implantFilter();

  $button.on("click", toggleDrop);

  $("a", $content).on("click", function() {
    var target = $(this).data("target"),
        filter = $rfaContainer.find("[data-target='" + target + "']");

    filter.removeClass("hidden");
    $content.toggle();
  });

  $("select", ".js-filter-container").on("change", function() {
    var columnIndex    = $(this).data("table-column-index"),
        selectData     = $(this).select2("data"),
        selectedFilter = $(this).attr("id"),
        searchParams   = new URLSearchParams(window.location.search),
        selectedValues = [],
        query          = [],
        dataTable;

    if (selectData.length > 0) {
      $.each(selectData, function(index, value) {
        query.push($.fn.dataTable.util.escapeRegex(value["text"]));
        selectedValues.push(value["id"]);
      });
    }

    dataTable = $("table[data-target=" + $el.data("table-target") + "]");

    if (dataTable.hasClass("dataTable")) {
      dataTable.DataTable().column(columnIndex).search(query.join("|"), true, false).draw();
    }
  });

  $("i", $tabContainer).on("click", function() {
    var filter       = $(this).parents("[data-target*='filter']"),
        input        = filter.find("select[data-type=filter_input]"),
        searchParams = new URLSearchParams(window.location.search);

    input.val(null).trigger("change");
    filter.addClass("hidden");
    searchParams.delete(input.attr("id"));
  });
}
