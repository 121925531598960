/* eslint no-console:0 */

import * as Sentry from "@sentry/browser";
import { initSentry } from "./js/utility/sentry"
initSentry(Sentry);

import { Application } from "@hotwired/stimulus"

const application = Application.start()
import controllers from "./**/*_controller.js";
controllers.forEach((controller) => {
  application.register(controller.name.replace("controllers--", ""), controller.module.default);
});

import "jquery";
import "./jquery";
import "./jquery-ui";
import "jquery-colorbox";
import select2 from "select2/dist/js/select2.full.min.js";
select2($);

import DataTable from "datatables.net";
require("datatables.net-buttons")(window, $);
require("datatables.net-buttons/js/buttons.html5.js")(window, $);

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage";
Rails.start();
ActiveStorage.start();
require("./channels");
import "daterangepicker";
window._ = require("lodash");
require("./js/vendor/auth_inbox");
import "mustache";
import "moment";
import "inputmask";
import "inputmask/lib/extensions/inputmask.numeric.extensions";
import "inputmask/lib/extensions/inputmask.date.extensions";
import "inputmask/dist/jquery.inputmask";
import "chartkick/chart.js";

require("./channels")
require("./js/vendor/auth_inbox")

//utility
require("./js/utility/colorbox")
require("./js/utility/dropdown")
require("./js/utility/jquery_custom_extensions").default.start()
require("./js/utility/position_modal")
require("./js/utility/push_state")
require("./js/utility/show_flash_message")

//vendor
require("./js/vendor/elemental.js")
require("./js/vendor/jquery.easing.js")

//shared
require("./js/shared/confirmation")
require("./js/shared/content_switcher")
require("./js/shared/data_table_downloader")
require("./js/shared/form_type")
require("./js/shared/multiple_select")
require("./js/shared/select2_basic")
require("./js/shared/show_modal_form")
require("./js/shared/show_popup")
require("./js/shared/simple_data_table")
require("./js/shared/preloaded_tabs")
require("./js/shared/tooltip")
require("./js/shared/date_range_picker")
require("./js/shared/date_picker")
require("./js/shared/time_picker")
require("./js/shared/turbolinks_scroll")

//custom
require("./js/custom/dependent_filters")
require("./js/custom/file_upload")
require("./js/custom/header_search")
require("./js/custom/hide_flash_message")
require("./js/custom/fade_flash_message")
require("./js/custom/rfa_filter")
require("./js/custom/embedded_articles_control")
require("./js/custom/collapse_table")

$(document).on("turbo:load", function() {
  $.colorbox.remove();

  try {
    Elemental.load(document);
  } catch (err) {
    Sentry.captureException(err);
  }

  try {
    localStorage
  } catch (error) {
    $(".js-cookies-notification").show();
  }

  if (navigator.cookieEnabled) {
    if (localStorage.getItem("turbolinks-scroll-top")) {
      $(document).scrollTop(localStorage.getItem("turbolinks-scroll-top"));
      localStorage.removeItem("turbolinks-scroll-top");
    }
  }
})

document.addEventListener("turbo:frame-render", function (event) {
  Elemental.load(event.target);
});

document.addEventListener("turbo:submit-end", function (event) {
  const { success, formSubmission } = event.detail;

  if (formSubmission.submitter) {
    formSubmission.submitter.disabled = success;
  }
});

document.addEventListener("turbo:before-stream-render", function (event) {
  const fallbackToDefaultActions = event.detail.render;

  event.detail.render = function (streamElement) {
    fallbackToDefaultActions(streamElement);

    let element = document.getElementById(streamElement.target);

    if (element !== null) {
      Elemental.load(element);
    }
  }
});

$(document).on("ajax:error", function(event) {
  if (event.detail[2].status == "401") {
    location.reload();
  }
});


document.addEventListener("turbolinks:request-start", function(event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader("X_TURBOLINKS_NONCE", $("meta[name='csp-nonce']").prop('content'));
});

document.addEventListener('turbolinks:before-cache', function() {
  $('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce);
  });
});

let typeKitLoadAttempts = 0;

function loadTypekit() {
  if (typeKitLoadAttempts > 10) {
    console.log("Typekit failed after 10 tries");
    return;
  } else {
    try {
      Typekit.load({ async: true });
    } catch(e) {
      typeKitLoadAttempts++;
      setTimeout(loadTypekit, 1000);
    }
  }
}

$(() => {
  $(document).on("click", "a", function(e) {
    if ((e.currentTarget.href || '').startsWith("javascript:")) {
      e.preventDefault();
    }
  });

  $(document).on("click", ".intercom--show-new-message", (e) => {
    e.preventDefault();
    return window.Intercom("showNewMessage");
  });
});

setTimeout(loadTypekit, 1);
