import WindowDatePicker from "window-date-picker"

const moment = require("moment")

AuthInbox.Shared.timePicker = function($container) {
  var $input          = $container.find("input"),
      inputFocusClass = "form-text-input--focus",
      $toggle         = $container.find(".js-time-picker-toggle"),
      initialValue    = "12:00 AM";

  const picker = new WindowDatePicker({
    el:          $container.find("#js-time-picker").get(0),
    inputEl:     $input.get(0),
    toggleEl:    $toggle.get(0),
    type:        "HOUR",
    inputToggle: true,
    orientation: true
  });

  $input.on("keypress", function(e) {
    if(e.which == 13) {
      validateTime();
    }
  });

  $input.inputmask({
    alias:           "datetime",
    inputFormat:     "hh:MM TT",
    placeholder:     $input.attr("placeholder"),
    hourFormat:      "12",
    showMaskOnHover: false,
    showMaskOnFocus: false,
    onincomplete:    validateTime
  });

  function validateTime() {
    if (!moment($input.val(), "hh:mm A", true).isValid()) {
      $input.val("");
      picker.close();
    }
  }

  ["wdp.change", "wdp.open"].forEach(function(e) {
    picker.el.addEventListener(e, () => {
      $input.addClass(inputFocusClass);
    });
  });

  picker.el.addEventListener("wdp.close", () => {
    $input.removeClass(inputFocusClass);
  });

  picker.el.addEventListener("wdp.open", () => {
    if ($input.val() == "") {
      picker.set(initialValue);
    }
  });

  $(".wdp-hour-el-container").find("input").on("focus", function() {
    $input.addClass(inputFocusClass);
  });
}
