const Mustache = require("mustache")

AuthInbox.Shared.tooltip = function($tooltip) {
  var showOnOverflowOnly = $tooltip.data("show-on-overflow-only") || false,
      tooltipTemplate =
        '<div class="tooltip {{ tooltipClass }}">' +
          '<div class="tooltip__title">{{ title }}</div>' +
          '<div class="tooltip__content {{ contentClass }}">{{{ content }}}</div>' +
        '</div>';

  if (overflow()) {
    $tooltip.addClass("cursor-pointer");
    $tooltip.on("mouseover", function() {
      var $tooltipPlace = tooltipContainer().find(".js-tooltip-place");

      var title        = $tooltip.data("title"),
          contentClass = $tooltip.data("content-class"),
          tooltipClass = $tooltip.data("tooltip-class"),
          content      = $tooltip.data("content"),
          $content     =
            Mustache.render(tooltipTemplate, {
              title: title,
              content: content,
              contentClass: contentClass,
              tooltipClass: tooltipClass
            });

      $tooltipPlace.html($content);
      properPlacement($tooltipPlace);
    });

    $tooltip.on("mouseout", function() {
      var $tooltipPlace = tooltipContainer().find(".js-tooltip-place");

      $tooltipPlace.empty();
    });
  } else {
    $tooltip.removeClass("cursor-pointer");
  }

  function overflow() {
    if (showOnOverflowOnly) {
      return ($tooltip[0].scrollWidth > Math.ceil($tooltip.width()))
    } else {
      return true
    }
  }

  function properPlacement($tooltipPlace) {
    var $tooltipContainer = $tooltipPlace.find(".tooltip"),
        tooltipGap = showOnOverflowOnly ? 0 : 8;

    $tooltipContainer.css("top", "-" + ($tooltipContainer.outerHeight() + tooltipGap) + "px");
  }

  function tooltipContainer() {
    var containerClass = $tooltip.data("tooltip-container-class"),
        $container     = $(`.${containerClass}`);

    if ($container.length) {
      return $container
    } else {
      return $tooltip.parent()
    }
  }
}
