AuthInbox.Utility.showFlashMessage = function(message, flashClass) {
  const flashClasses = {
    "success": ".js-flash-message-success",
    "alert":   ".js-flash-message-alert",
  }

  var $flash = $(flashClasses[flashClass]);

  $flash.find(".js-flash-message-content").html(message);
  $flash.fadeIn();
  Elemental.load($flash);
};
