AuthInbox.Utility.colorbox = function($el, options) {
  var DEFAULT_OPTIONS = {
        opacity: 0.75,
        returnFocus: true,
        overlayClose: false,
        closeButton: true,
        speed: 300,
        transition: "none"
      },
      onOpenCallback   = options.onOpen,
      onClosedCallback = options.onClosed,
      scrollPadding,
      overrides;

  function defaultOnOpen() {
    var windowsWidth = $(window).width();
    $("html").css({ "overflow-y": "hidden" });
    scrollPadding = $(window).width() - windowsWidth;
    setUpCloseButton();
  }

  function defaultOnClosed() {
    $("html").css({ "overflow-y": "auto" });
  }

  function setUpCloseButton() {
    $("#colorbox").on("click", ".cancel", function(event) {
      event.preventDefault();
      $el.colorbox.close();
    });
  }

  overrides = {
    onOpen: function() {
      defaultOnOpen();
      if ($.isFunction(onOpenCallback)) {
        onOpenCallback();
      }
    },
    onClosed: function() {
      defaultOnClosed();
      if ($.isFunction(onClosedCallback)) {
        onClosedCallback();
      }
    }
  };

  $el.colorbox($.extend(DEFAULT_OPTIONS, options, overrides));
};
