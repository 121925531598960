import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    resizeModal: { type: Boolean, default: false }
  }

  resizeModal() {
    if (this.resizeModalValue) {
      AuthInbox.Utility.positionModal()
    }

    let scrollableModalBody = document.querySelector(".modal__body--scrollable")

    if (scrollableModalBody) {
      scrollableModalBody.scrollTo({ top: scrollableModalBody.scrollHeight, behavior: "smooth" })
    }
  }

  process(event) {
    const { success } = event.detail

    if (success) {
      $.colorbox.close()
    }
  }
}
