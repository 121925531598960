require("./colorbox")

AuthInbox.Utility.remoteForm = function(el, options) {
  var $el            = $(el),
      defaultOptions = {
        open: true,
        closeButton: false,
        onComplete: setUp,
        onLoad: fixSelect2
      };

  AuthInbox.Utility.colorbox($el, $.extend(defaultOptions, options));

  function setUp() {
    Elemental.load($('#colorbox'));
    $('input, select, a, textarea', '#colorbox').filter(':visible:not([readonly]):first').focus();
    $el.trigger("remoteFormLoaded");
    AuthInbox.Utility.positionModal();

    $('#colorbox').on('click', '.cancel', function(event) {
      event.preventDefault();
      $.colorbox.close();
    });
  }

  function fixSelect2() {
    $("#colorbox").removeAttr("tabindex");
  }
};
