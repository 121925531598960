// // like $.isFunction

const extensions = {
  start: function() {
    $.isUndefined = function(value) {
      return typeof(value) === 'undefined';
    };

    $.remote = function(url, params) {
      $.get(url, params, undefined, 'script');
    };

    $.fn.reverse = [].reverse;

    $.fn.isInViewport = function() {
      var elementTop     = $(this).offset().top,
          elementBottom  = elementTop + $(this).outerHeight(),
          viewportTop    = $(window).scrollTop(),
          viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };
  }
}

export default extensions
