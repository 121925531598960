AuthInbox.Shared.simpleDataTable = function($table, options) {
  if ($table.prop("class") && $table.prop("class").match("dataTable")) {
    return null;
  }

  var $dataTable,
      $tableWrapper,
      selectedPaginationValue,
      tableStyle = $table.data("style"),
      initialSort = $table.data("sort"),
      actionLink = $table.data("action-link"),
      disableDefaultSorting = $table.data("disableDefaultSorting"),
      lengthMenu = $table.data("itemsCountList"),
      pageLength = $table.data("defaultItemCount") || 10,
      errorMessage = "We're sorry, something went wrong",
      exportCSV = $table.data("export-csv") || false,
      csvFileName = $table.data("csv-file-name"),
      exportButtonContainer = $table.data("export-button-container"),
      placeholder = $table.data("placeholder") || "Search Table",
      paginationPosition = $table.data("pagination-position") || "top",
      scrollX = $table.data("scroll") || false,
      autoWidth = $table.data("auto-width") || false,
      hiddenColumns = $table.data("hidden-columns") || [],
      disableDefaultSearch = $table.data("disable-default-search") || false,
      pageLengthSelector = $table.data().pageLengthSelector || true,
      ajaxSource = $table.data("ajax-source"),
      dataTableOptions = $.extend({
        sPaginationType: "simple",
        oLanguage: {
          sSearch: "",
          oPaginate: { sPrevious: "", sNext: "" },
          sInfo: "Showing _TOTAL_ Items",
          sProcessing: "Loading...",
          sInfoEmpty: "No records"
        },
        fnStateLoadParams: fnStateLoadParams,
        fnStateSaveParams: fnStateSaveParams,
        iDisplayLength: pageLength,
        fnInitComplete: fnInitComplete,
        orderClasses: false,
        scrollX: scrollX,
        autoWidth: autoWidth,
        sDom: sDom(),
        fnDrawCallback: fnDrawCallback,
        bAutoWidth: false,
        bSort: enableSorting(),
        infoCallback: infoCallback,
        lengthMenu: [pageLength, 25, 100],
        aoColumnDefs: [
          {
            "bSortable" : false,
            "aTargets" : [ "js-no-sort" ]
          },
          {
            "aTargets": hiddenColumns,
            "bVisible": false
          }
        ]
      }, options);

  function fnDrawCallback() {
    addPageNumbers();
    if (actionLink) {
      addActionLink();
    }
  }

  function addActionLink() {
    var $tableWrapperParent = $table.parents(".dataTables_wrapper").parent(),
        $actionLink =
          '<div class="js-action-container action-container">' +
            '<div class="js-action-link action-link">' + actionLink + '</div>' +
          '<div class="js-action-content"></div>';

    if ($(".js-action-container", $tableWrapperParent).length === 0) {
      $(".dt-simple__search", $tableWrapperParent).after($actionLink);
    }
  }

  function fnStateLoadParams(settings, data) {
    selectedPaginationValue = data.length;
  }

  function fnStateSaveParams(oSettings, oData) {
    delete oData.search;
    delete oData.columns;
    delete oData.order;
  }

  function addPageNumbers() {
    var text = ($table.DataTable().page.info().page + 1) + " / " + totalPageSize(),
        $paginate_prev_button = $($table.DataTable().table().container()).find(".paginate_button.previous");
    $("<span class='dt-simple__page-numbers'>" + text + "</span>").insertAfter($paginate_prev_button);
  }

  function totalPageSize() {
    var pageCount = $table.DataTable().page.info().pages;

    if (pageCount === 0) {
      return 1;
    } else {
      return pageCount;
    }
  }

  function tableInformation() {
    return "<'dt-simple__information'i>";
  }

  function sDom() {
    if (paginationPosition === "no_pagination") {
      return (
        '<"' + tableClass() + '"t>'
      );
    } else if (paginationPosition === "only_top") {
      return (
        '<"dt-simple__header"' +
          pageLengthLinks() +
          tableInformation() +
          searchInput() +
          '<"dt-simple__pagination"p>' +
        '>' +
        '<"' + tableClass() + '"t>'
      );
    } else if (paginationPosition === "only_bottom") {
      return (
        tableInformation() +
        '<"' + tableClass() + '"t>'
      );
    } else if (tableStyle === "basic") {
      return (
        '<"' + tableClass() + '"t>'
      );
    } else if (pageLengthSelector === true) {
      return (
        '<"dt-simple__header"' +
          pageLengthLinks() +
          tableInformation() +
          searchInput() +
          '<"dt-simple__pagination"p>' +
        '>' +
        '<"' + tableClass() + '"t>'
      );
    } else {
      return (
        '<"dt-simple__header"' +
          tableInformation() +
          searchInput() +
          '<"dt-simple__pagination"p>' +
        '>' +
        '<"' + tableClass() + '"t>'
      );
    }
  }

  function searchInput() {
    if (disableDefaultSearch) {
      return("");
    } else {
      return('<"dt-simple__search"f>');
    }
  }

  function tableClass() {
    if (tableStyle === "compact") {
      return "dt-simple__table dt-simple__table--compact";
    } else {
      return "dt-simple__table";
    }
  }

  function setupPageLengthSelector() {
    $(".dt-simple__page-length select option", $tableWrapper).each(function() {
      var $link = $('<a href="javascript:void(0);" class="js-page-length-choice">'+$(this).text()+'</a><span>|</span>');

      $(".dt-simple__page-length-links", $tableWrapper).append($link);
    });

    $(".dt-simple__page-length", $tableWrapper).hide();
    $(".js-page-length-choice", $tableWrapper).each(function(index, choise) {
      var $choise = $(choise);

      if ($choise.html() == String(pageLength)) {
        $choise.addClass("active");
      }
    });
    $(".js-page-length-choice", $tableWrapper).on("click", pageLengthChange);
  }

  function pageLengthChange(event) {
    $(".js-page-length-choice", $tableWrapper).each(function() {
      $(this).removeClass("active");
    });

    $(event.target, $tableWrapper).addClass("active");
    pageLength = parseInt(event.target.innerText, 10);
    $(".dataTables_length select option[value=" + pageLength + "]", $tableWrapper).prop("selected", true).trigger("change");
  }

  function pageLengthLinks() {
    if (pageLengthSelector) {
      return "<'dt-simple__page-length'l><'dt-simple__page-length-links'>";
    } else {
      return "";
    }
  }

  function fnInitComplete() {
    $(".dataTables_filter input", $tableWrapper).attr("placeholder", placeholder);
    $(".dataTables_filter", $tableWrapper).before("<i class='fa fa-search'></i>");

    if (exportCSV) {
      $table.DataTable().buttons( 0, null ).container().appendTo($(exportButtonContainer));
    }

    $(".dt-simple").css("visibility", "visible");
    $(".js-spinner").remove();
  }

  function enableSorting() {
    if ($.isUndefined($table.data("enable-sorting"))) {
      return true;
    } else {
      return $table.data("enable-sorting");
    }
  }

  function infoCallback(settings, start, end, max, total, pre) {
    if (total > 0) {
      var formattedTotal = new Intl.NumberFormat().format(total)

      return `Viewing ${end - start + 1} of ${formattedTotal}`;
    }
  }

  function addCsvButtonOptions() {
    $.extend(dataTableOptions, {
      buttons: [
        {
          extend: "csv",
          text: "Download",
          className: "btn btn--blue-outline",
          title: csvFileName
        }
      ]
    });
  }

  $.fn.dataTable.ext.errMode = "none";

  // EXTEND OPTIONS
  if (initialSort) {
    $.extend(dataTableOptions, { aaSorting: initialSort });
  }

  if (disableDefaultSorting) {
    $.extend(dataTableOptions, { aaSorting: [] });
  }

  if (ajaxSource) {
    $.extend(dataTableOptions, { ajax: ajaxSource, bServerSide: true });
  }

  if (exportCSV) {
    addCsvButtonOptions();
  }
  // END EXTEND OPTIONS

  Object.assign(dataTableOptions);

  // Main call
  $dataTable = $table.dataTable(dataTableOptions);
  $tableWrapper = $table.parents(".dataTables_wrapper");

  if (pageLengthSelector) { setupPageLengthSelector(); }

  $tableWrapper.on("show", function() {
    var paginationLinkPosition = 0,
        $paginationContainer   = $(".dt-simple__page-length-links", $tableWrapper),
        paginationValues       = { 10: 0, 25: 1, 100: 2 };

    if (selectedPaginationValue) {
      paginationLinkPosition = paginationValues[selectedPaginationValue];
    }

    $($paginationContainer.find("a")[paginationLinkPosition]).trigger("click");
    $paginationContainer.show();
  })

  function pushParams(key, value) {
    $(".js-download-csv").data(key, value);
  }

  $table.on("order.dt", function(e, settings) {
    if (ajaxSource) {
      $.each($table.DataTable().order(), function(index, orderParams) {
        pushParams("iSortCol_0", orderParams[0]);
        pushParams("sSortDir_0", orderParams[1]);
      })
    }
  });

  $table.on("search.dt", function(e, settings) {
    if (ajaxSource) {
      pushParams("sSearch", $table.DataTable().search());
    }
  });

  return $dataTable;
};
