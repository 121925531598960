AuthInbox.Shared.dateRangePicker = function($el) {
  const moment = require("moment")

  var $startDateTarget = $el.closest(".js-date-range-picker").find(".js-start-date"),
      $endDateTarget   = $el.closest(".js-date-range-picker").find(".js-end-date");

  const dateFormat       = "YYYY-MM-DD",
        sourceDateFormat = "MM-DD-YYYY";

  if ($startDateTarget.val() !== "") {
    $el.val(startDate() + " - " + endDate());
  }

  $startDateTarget.val(moment(startDate(), sourceDateFormat).format(dateFormat));
  $endDateTarget.val(moment(endDate(), sourceDateFormat).format(dateFormat));

  $el.daterangepicker(
    {
      parentEl: $el.parent(),
      linkedCalendars: false,
      opens: $el.data("opens"),
      autoApply: true,
      ranges: ranges(),
      cancelClass: "btn--outline",
      startDate: startDate(),
      endDate: endDate(),
      alwaysShowCalendars: true,
      locale: {
        "daysOfWeek": [
          "S",
          "M",
          "T",
          "W",
          "T",
          "F",
          "S"
        ],
      }
    },
    function(start, end) {
      $startDateTarget.val(start.format(dateFormat));
      $endDateTarget.val(end.format(dateFormat));
      $el.val(startDate() + " - " + endDate());
    }
  );

  $el.on("show.daterangepicker", function(e, picker) {
    picker.container.toggleClass("daterangepicker--open");

    // Move calendars inside of a parent div so that we can style it correctly
    if (picker.container.find(".drp-calendar-container").length === 0) {
      $calendarContainer = $("<div class='drp-calendar-container'></div>");
      $calendarContainer.insertAfter(picker.container.find(".ranges"));
      $(picker.container.find(".drp-calendar")).detach().appendTo($calendarContainer);
    }
  });

  $el.on("hide.daterangepicker", function(e, picker) {
    picker.container.toggleClass("daterangepicker--open");
  });

  function startDate() {
    return moment($startDateTarget.val()).format("L") || moment().startOf("month").format("L");
  }

  function endDate() {
    return moment($endDateTarget.val()).format("L") || moment().format("L");
  }

  function ranges() {
    return {
      "Week to date": [moment().startOf("week"), moment()],
      "Last Week": [moment().subtract(1, "week").startOf("week"), moment().subtract(1, "week").endOf("week")],
      "Month to Date": [moment().startOf("month"), moment()],
      "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
      "Year to Date": [moment().startOf("year"), moment()]
    }
  }
};
