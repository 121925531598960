import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "icon", "container" ]
  static values  = { open: Boolean }

  connect() {
    if (this.openValue) {
      this.update();
    }
  }

  update() {
    this.containerTarget.classList.toggle("hidden")
    this.iconTarget.classList.toggle("fa-angle-up")
    this.iconTarget.classList.toggle("fa-angle-down")
  }
}
