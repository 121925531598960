AuthInbox.Shared.preloadedTabs = function($container) {
  var activeClass = "text-gray-900 hover:text-gray-900",
      inactiveClass = "text-gray-300 hover:text-gray-400",
      parentClass = $container.data("parent-class"),
      $tabsContainer = $("." + parentClass),
      tabContainerClass = ".js-tab-content",
      $tabs = $container.find("a");

  $tabs.on("click", function(e) {
    var $checkedTab = $(this),
        checkedTabName = $(this).data("tab-name"),
        $newActiveContainer = $tabsContainer.find("[data-toogle-tab-name='" + checkedTabName + "']");

    $tabs.removeClass(activeClass);
    $tabs.addClass(inactiveClass);
    $checkedTab.addClass(activeClass);

    $tabsContainer.find(tabContainerClass).hide();
    $newActiveContainer.show();
  });
};
