AuthInbox.Shared.showPopup = function($link) {
  var target = $link.attr("href") || $link.attr("target");

  $link.attr("href", "");
  $link.attr("target", target);

  var $content = $(target),
      options  = {
        html: $content.html(),
        closeButton: false,
        onComplete: setUp
      };

  AuthInbox.Utility.colorbox($link, options);

  function setUp() {
    Elemental.load($('#colorbox'));
    $.colorbox.resize();
  }
};
