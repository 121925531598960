AuthInbox.Custom.hideFlashMessage = function($flash) {
  var $dismiss = $flash.find(".js-flash-message-dismiss");

  function hideMessage() {
    $flash.fadeOut();
  }

  if ( $flash.is(":visible") ) {
    setTimeout(hideMessage, 5000);
  }

  $dismiss.on("click", function() {
    hideMessage();
  });
};
