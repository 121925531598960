AuthInbox.Custom.dependentFilters = function($container) {
  var $firstColumnBox = $container.find(".js-filter-box-group-column"),
      $secondBoxColumn = $(".js-filter-box-column");

  $firstColumnBox.on("change", "input[type=radio]", function() {
    var value = $(this).val(),
        $input = $secondBoxColumn.find("input[type=radio][value=" + value + "]");

    enableAllInputsExcept($input);
    disableSecondFilter($input)
  });

  $(".js-clear-selection").on("click", function() {
    $(this).parent().find("input[type=radio]:enabled").prop("checked", false);
  });

  function enableAllInputsExcept($input) {
    $secondBoxColumn.find("input[type=radio]").not($input).attr("disabled", false).
      parent().removeClass("custom-control--disabled");
  }

  function disableSecondFilter($input) {
    if ($input.length > 0) {
      $input.attr("disabled", true).prop("checked", false).parent().addClass("custom-control--disabled");
    }
  }

  function autoDisableSecondFilter() {
    $firstColumnBox.find("input[type=radio]:checked").trigger("change");
  }

  autoDisableSecondFilter();
};
