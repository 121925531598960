AuthInbox.Shared.multipleSelect = function($el) {
  $el.select2({
    maximumSelectionSize: $el.data("max-selection-size") || 0,
    allowClear: true,
    data: $el.data("options").options,
    multiple: true
  });

  $el.select2("val", $el.data("selected"));
};
