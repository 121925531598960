AuthInbox.Shared.contentSwitcher = function($container) {
  var $radios = $("input.switcher", $container),
      methods = {
        "show": function($content, state) {
          methods.hide($content, !state);
        },
        "hide": function($content, state) {
          if (state) {
            $content.show();
          } else {
            $content.hide();
          }
          $("input, select", $content).prop("disabled", !state);
        },
        "enable": function($content, state) {
          methods.disable($content, !state);
        },
        "disable": function($content, state) {
          $("input, select, button", $content).prop("disabled", !state);
        }
      },
      method = methods[$container.data("method")];

  $radios.each(function(ignore, radio) {
    var $radio = $(radio);

    $radio.data("$container", $($radio.data("container")));
  });

  function switchContent() {
    $radios.each(function(ignore, radio) {
      var $radio = $(radio);

      method($radio.data("$container"), $radio.is(":checked"));
    });

    $.colorbox.resize();
  }

  $radios.on("click", switchContent);
  switchContent();
};
